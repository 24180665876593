<template>
  <div class="comm-notice-post-container center">
    <div class="comm-notice-post">
      <hr>
      <div class="comm-notice-post-title">
        {{NOTICE_POST.title}}
      </div>
      <div class="comm-notice-post-info">
        <span class="comm-notice-post-info-text">작성날짜 : {{changeDateFormat(NOTICE_POST.created_at)}}</span>
        <span class="comm-notice-post-info-text">조회수 : {{NOTICE_POST.view}}</span>
      </div>
      <div class="comm-notice-post-content" v-html="NOTICE_POST.content">
      </div>
      <hr>
      <a class="comm-notice-post-file" 
      :href="NOTICE_POST.src" download
      v-if="NOTICE_POST.src">
      <img class="default-margin" :src="fileIcon"/>
        {{NOTICE_POST.src.split('/')[3]}}
      </a>
      
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import fileIcon from '../../assets/icons/file_icon.svg'

export default {
  data() {
    return {
      fileIcon
    }
  },

  created() {
    this.FETCH_NOTICE_POST(this.$route.params.id)
  },

  methods : {
    ...mapActions([
      'FETCH_NOTICE_POST'
    ]),

    changeDateFormat(date) {
        const d = new Date(date)
        return moment(d).format('YY-MM-DD')
    },
  },

  computed : {
    ...mapState([
      'NOTICE_POST'
    ])
  }
}
</script>

<style>

</style>
